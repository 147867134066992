/**
 * @name: 销售管理-小票出票设置接口文件
 * @author:lili
 * @date: 2023-08-04 17:46
 * @description： 销售管理-小票出票设置接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IScaleCashier} from "@/apis/sale/ticketing/types";



/**
 * 获取详情
 * @param id
 * @returns
 */
export const cashierScaleDetailApi = () => get<IScaleCashier>("/admin/cashier/scale/receipt/detail",{})

/**
 * 修改
 * @param data
 * @returns
 */
export const cashierScaleAmountApi = (data: IScaleCashier) => postJ("/admin/cashier/scale/receipt/modify", data)
