
/**
 * @name: 销售管理-小票出票设置
 * @author: lili
 * @date: 2023-08-04 17:53
 * @description： 销售管理-小票出票设置
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import {IScaleCashier} from "@/apis/sale/ticketing/types";
import {cashierScaleAmountApi, cashierScaleDetailApi} from "@/apis/sale/ticketing";

@Component({})
export default class saleTicketing extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = [
    {
      type: '微信',
      value: '0',
      isEdit: false
    },
    {
      type: '支付宝',
      value: '0',
      isEdit: false
    },
    {
      type: '现金',
      value: '0',
      isEdit: false
    },
    {
      type: '混合支付',
      value: '0',
      isEdit: false
    },
    {
      type: '挂账',
      value: '0',
      isEdit: false
    }
  ];

  // 表单参数
  modelForm: Partial<IScaleCashier> = {}

  /**
   * 显示 输入框
   */
  async showEditPrice(row: any, index: number) {
    await this.getDetail();
    setTimeout(() => {
      this.tableData[index].isEdit = true;
    }, 300)
  }

  /**
   * 提交修改
   * @param row
   */
  submitPrice(row: any, index: number) {

    if (!row.value || (!/^[1-9][0-9]*$/.test(row.value))) {
      this.$message({
        type: 'error',
        message: '请输入出票张数！'
      });
      return
    }
    this.tableLoading=true
    let obj:IScaleCashier=this.modelForm as any;
    for (let i = 0; i < this.tableData.length; i++) {
      switch (this.tableData[i].type) {
        case '微信':
          obj.weChat = this.tableData[i].value;
          break
        case '支付宝':
          obj.aliPay =this.tableData[i].value;
          break
        case '现金':
          obj.cash =this.tableData[i].value;
          break
        case '混合支付':
          obj.mixPay =this.tableData[i].value;
          break
        case '挂账':
          obj.hangAccount =this.tableData[i].value;
          break
      }
    }
    cashierScaleAmountApi(obj).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getDetail();
      }
    })

  }

  /**
   * 获取详情
   */
  getDetail() {
    cashierScaleDetailApi().then(e => {
      if (e) {
        this.modelForm=e;
        for (let i = 0; i < this.tableData.length; i++) {
          let val = '';
          switch (this.tableData[i].type) {
            case '微信':
              val = e.weChat;
              break
            case '支付宝':
              val = e.aliPay;
              break
            case '现金':
              val = e.cash;
              break
            case '混合支付':
              val = e.mixPay;
              break
            case '挂账':
              val = e.hangAccount;
              break
          }
          const item = this.tableData[i]
          // this.tableData[i].value=val;
          // this.tableData[i].isEdit=false;
          this.$set(this.tableData, i, {
            ...item,
            value: val,
            isEdit: false
          })
        }
      }
      this.tableLoading=false
    })
  }

  created () {
    this.getDetail()
  }
}
